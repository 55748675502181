<template>
	<div>
		<h3>Please verify your email address.</h3>
		<p>You should have received an automated email from our system with a link to click that verifies we have the correct email. If you don't see it, please check in your junk folder.</p>
		<button class="btn btn__primary mt-3" @click="resend()">
			Resend Verification Email
			<transition name="fade">
        <span class="ml-2" v-if="performingRequest">
        <i class="fa fa-spinner fa-spin"></i>
        </span>
      </transition>
		</button>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	data: () => ({
    performingRequest: false,
  }),
  computed: {
    ...mapState(['currentUser', 'customerProfile']),
  },
  methods: {
  	resend() {
      this.performingRequest = true
      setTimeout(() => {
      this.currentUser.sendEmailVerification()
      this.performingRequest = false
      }, 1000)
    },
  }
}
	
</script>