<template>
	<div class="dashboard">
		<Breadcrumbs :items="crumbs" />
    <div class="dashboard__main">
      <div class="dashboard__main--header">
        <h1 class="ml-3 mt-3">Account Details</h1>
        <router-link :to="{ name: 'dashHome' }">
          <button class="btn"><i class="fad fa-arrow-left fa-2x"></i></button>
        </router-link>
      </div>
      <hr>
      <div class="flex flex-wrap">
        <div class="flex__col1">
          <transition name="fade">
            <Loader v-if="!customerProfile" />
          </transition>
          <form @submit.prevent v-if="customerProfile">
            <div>
              <label for="firstName">Primary Contact's First Name</label>
              <input type="text" placeholder="First Name" v-model.trim.lazy="customerProfile.firstName" id="firstName" required />
            </div>
            <div>
              <label for="lastName">Primary Contact's Last Name</label>
              <input type="text" placeholder="Last Name" v-model.trim.lazy="customerProfile.lastName" id="lastName" required/>
            </div>
            <div>
              <label for="entity">Entity Name</label>
              <input type="text" placeholder="California Department of ..." v-model.trim.lazy="customerProfile.entity" id="entity" required />
            </div>
            <div>
              <label for="phone">Primary Contact's Phone</label>
              <input type="text" placeholder="(###) ###-####" v-mask="'(###)-###-####'" v-model.trim="customerProfile.phone" id="phone" />
            </div>
            <div>
              <label for="email">Primary Contact's Email</label>
              <input type="text" placeholder="you@email.com" v-model.trim.lazy="customerProfile.email" id="email" required />
            </div>
            
            <div>
              <label for="anythingElse">Is there anything about your entity that we need to be aware of?</label>
              <textarea id="anythingElse" cols="30" rows="4" v-model.trim.lazy="customerProfile.anythingElse"></textarea>
            </div>
            
            <button class="btn btn__primary btn__large mt-3 mb-3" @click="submit()">
              Update
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </form>
        	
        </div>
        <div class="flex__col2">
        	<transition name="fadeStop">
            <VerifyEmail v-if="!currentUser.emailVerified" />
          </transition>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import VerifyEmail from '@/components/VerifyEmail.vue'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'account',
  data: () => ({
    performingRequest: false,
    errors: []
  }),
  components: {
    Loader,
    Breadcrumbs,
    VerifyEmail
  },
  computed: {
    ...mapState(['currentUser', 'customerProfile']),
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Dashboard", to: { name: "dashHome"}}
      let step2 = { title: "Account Details", to: false}
      crumbsArray.push(step1)
      crumbsArray.push(step2)
      return crumbsArray
    },
  },
  methods: {
    submit() {
      this.performingRequest = true
      let thisUser = fb.auth.currentUser
      console.log(fb.auth.currentUser)
      thisUser.updateEmail(this.customerProfile.email).then(function() {
      }).catch(function(error) {
        console.log(error)
      })
      let {customerProfile} = this;
      fb.customersCollection.doc(this.customerProfile.id).update(customerProfile)
      setTimeout(() => {
          this.performingRequest = false
      }, 1000)
    },
  }
}
</script>